// extracted by mini-css-extract-plugin
export var automotiveBenefitsSection = "T_jy";
export var automotiveCasesSection = "T_jD";
export var automotiveClientQuotesSection = "T_jK";
export var automotiveDevExpertiseSection = "T_jH";
export var automotiveIndustriesSection = "T_jx";
export var automotiveProcessSection = "T_jz";
export var automotiveProjLogoSection = "T_jG";
export var automotiveRequirementsSection = "T_jC";
export var automotiveServicesSection = "T_jw";
export var automotiveSkilledSection = "T_jJ";
export var automotiveTechStackSection = "T_jB";
export var automotiveWhyHireSection = "T_jF";